import React from 'react';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import { showToast } from './Toast';
import { Typography, IconButton, Tooltip } from '@material-ui/core';

const Output = props => {
  let { label, children, copy } = props;

  const renderCopy =
    copy && document.queryCommandSupported('copy') ? (
      <React.Fragment>
        <Tooltip title={'Copy'}>
          <IconButton onClick={() => copyToClipboard(children)}>
            <CopyIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ) : null;

  return (
    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      <div style={{ width: '100%', wordBreak: 'break-word' }}>
        {label && (
          <Typography variant="caption" style={{ opacity: 0.7 }}>
            {label}
          </Typography>
        )}

        <Typography variant="body1">{children}</Typography>
      </div>
      {renderCopy}
    </div>
  );
};

export default Output;

const copyToClipboard = text => {
  var el = document.createElement('textarea');
  // Set value (string to be copied)
  el.value = text;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
  showToast({ text: 'Copied to clipboard: ' + text });
};
