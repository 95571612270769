import React from 'react';
import TrezorConnect from 'trezor-connect';
import StellarSdk from 'stellar-sdk';
import LedgerTransport from '@ledgerhq/hw-transport-u2f';
import LedgerStr from '@ledgerhq/hw-app-str';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import colors from './config/colors.json';
import Button from './components/Button';
import Page from './components/Page';
import { TextField, Typography } from '@material-ui/core';
import Output from './components/Output.js';
import Toast from './components/Toast.js';
import BigNumber from "bignumber.js"

var Buffer = require('buffer/').Buffer;
const DEVICE_PREFIX = "m/44'/148'/";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signing_device: null, // trezor, ledger, secret
      xdr: '',
      signed_xdr: '',
      bip32Account: '0',
    };
  }

  handleXdrInput(event) {
    this.setState({
      xdr: event.target.value,
    });
  }

  handleBip32AccountInput(event) {
    this.setState({
      bip32Account: event.target.value,
    });
  }

  signWithSecret() {
    this.setState({
      signing_device: 'secret',
    });
    const transaction = consumeXDRObject(this.state.xdr);
  }

  async signWithTrezor() {
    this.setState({
      signing_device: 'trezor',
    });
    const bip32Path = DEVICE_PREFIX + this.state.bip32Account + "'";
    const public_address = await getTrezorPublicAddress(bip32Path);
    console.log(this.state.xdr)
    const transaction = await consumeXDRObject(this.state.xdr);
    console.log(transaction)
    const trezor_sig = await signWithTrezor(transaction, bip32Path);
    console.log(trezor_sig)
    const sig_uint8buffer = Buffer.from(trezor_sig.signature, 'hex');
    console.log(sig_uint8buffer.toString('base64'))
    console.log(public_address)
    transaction.addSignature(
      public_address.address,
      sig_uint8buffer.toString('base64'),
    );
    const signed_xdr = transaction.toXDR();
    this.setState({
      signed_xdr: signed_xdr,
    });
  }

  async signWithLedger() {
    this.setState({
      signing_device: 'ledger',
    });
    const bip32Path = DEVICE_PREFIX.substr(2) + this.state.bip32Account + "'";
    const transaction = await consumeXDRObject(this.state.xdr);
    const transport = await LedgerTransport.create();
    const str = new LedgerStr(transport);
    const ledger_sig = await str.signTransaction(
      bip32Path,
      transaction.signatureBase(),
    );
    const public_key = await str.getPublicKey(bip32Path)
    const sig_uint8buffer = Buffer.from(ledger_sig.signature, 'hex');
    transaction.addSignature(
      public_key.publicKey,
      sig_uint8buffer.toString('base64'),
    );
    const signed_xdr = transaction.toXDR();
    this.setState({
      signed_xdr: signed_xdr,
    });
  }

  renderLanding() {
    return (
      <div>
        <div>
          <TextField
            label={'Transaction object'}
            style={{ width: '100%', paddingBottom: 24 }}
            helperText={'Raw XDR transaction object'}
            // rows="3"
            multiline
            value={this.state.xdr}
            onChange={this.handleXdrInput.bind(this)}
          />

          <Typography style={{ fontSize: 12 }}>Device account</Typography>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              paddingBottom: 24,
            }}>
            <Typography style={{ paddingRight: 4 }}>{DEVICE_PREFIX}</Typography>
            <TextField
              style={{ width: 12 }}
              value={this.state.bip32Account}
              onChange={this.handleBip32AccountInput.bind(this)}
            />
            <Typography>'</Typography>
          </div>
        </div>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button onClick={this.signWithTrezor.bind(this)}>
            Sign with Trezor
          </Button>
          <Button onClick={this.signWithLedger.bind(this)}>
            Sign with Ledger
          </Button>
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        {this.state.signed_xdr ? (
          <div style={{ width: '100%', 'overflow-wrap': 'break-word' }}>
            <Output label={'Signed XDR:'} copy>
              {this.state.signed_xdr}
            </Output>
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            <Typography>
              Signing transaction... Follow instructions on hardware device.
            </Typography>
          </div>
        )}
      </div>
    );
  }

  render() {
    let theme = createMuiTheme({
      palette: {
        primary: { main: colors.primary },
        secondary: {
          main: colors.secondary,
          contrastText: colors.secondaryContrast,
        },
        text: {
          primary: '#777',
          secondary: '#777',
        },
      },

      typography: {
        h6: { lineHeight: 1.2, fontSize: 18, color: '#777' },
        body1: { lineHeight: 1.2, color: '#777' },
        body2: { lineHeight: 1.2, color: '#777' },
        h5: { fontWeight: 400, color: '#777', fontSize: '1.2rem' },
        h4: {
          color: '#777',
          fontSize: '1.6rem',
        },
      },
      shape: { borderRadius: 10 },
    });

    return (
      <React.Fragment>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <Page>
            {this.state.signing_device === null
              ? this.renderLanding()
              : this.renderLoading()}
          </Page>
          <Toast />
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

async function consumeXDRObject(xdr) {
  const server = new StellarSdk.Server('https://horizon.stellar.org');
  StellarSdk.Network.usePublicNetwork();
  const transaction = new StellarSdk.Transaction(xdr);
  return transaction;
}

// async function broadcastTransaction(transaction) {
//   const server = new StellarSdk.Server('https://horizon.stellar.org');
//   StellarSdk.Network.usePublicNetwork();
//   return await server.submitTransaction(transaction);
// }

async function signWithTrezor(transaction, bip32Path) {
  const trezor_tx = convertSdkTransactionToTrezor(transaction);
  TrezorConnect.manifest({
    email: 'connor@rehive.com',
    appUrl: 'stellar-signer.rehive.com',
  });
  const signature = await TrezorConnect.stellarSignTransaction({
    path: bip32Path,
    networkPassphrase: 'Public Global Stellar Network ; September 2015',
    transaction: trezor_tx,
  });
  return signature.payload;
}

async function getStellarAccountDetails(public_address) {
  const server = new StellarSdk.Server('https://horizon.stellar.org');
  StellarSdk.Network.usePublicNetwork();
  const account = await server.loadAccount(public_address);
  return account;
}

async function getTrezorPublicAddress(bip32Path) {
  TrezorConnect.manifest({
    email: 'connor@rehive.com',
    appUrl: 'stellar-signer.rehive.com',
  });
  const result = await TrezorConnect.stellarGetAddress({ path: bip32Path });
  return result.payload;
}

function convertSdkTransactionToTrezor(transaction) {
  let trezor_tx = {
    source: transaction.source,
    fee: transaction.fee,
    sequence: transaction.sequence,
    memo: { type: 0 },
    timebounds: {},
    operations: transaction.operations,
  };

  if (transaction.timeBounds) {
    trezor_tx.timebounds = {
      maxTime: parseInt(transaction.timeBounds.maxTime),
      minTime: parseInt(transaction.timeBounds.minTime),
    };
  }

  if (trezor_tx.operations) {
    trezor_tx.operations.map((value, key) => {
      if (trezor_tx.operations[key].amount) {
        const num = new BigNumber(value.amount)
        trezor_tx.operations[key].amount = num.times(10000000).toFixed();
      }
      if (trezor_tx.operations[key].limit) {
        const num = new BigNumber(value.limit)
        trezor_tx.operations[key].limit = num.times(10000000).toFixed();
      }
      if (trezor_tx.operations[key].asset) {
        if (trezor_tx.operations[key].asset.code === 'XLM') {
          trezor_tx.operations[key].asset = null;
        } else {
          if (trezor_tx.operations[key].asset.code.length <= 4) {
            trezor_tx.operations[key].asset = {
              code: trezor_tx.operations[key].asset.code,
              type: 1,
              issuer: trezor_tx.operations[key].asset.issuer,
            };
          } else if (trezor_tx.operations[key].asset.code.length <= 12) {
            trezor_tx.operations[key].asset = {
              code: trezor_tx.operations[key].asset.code,
              type: 2,
              issuer: trezor_tx.operations[key].asset.issuer,
            };
          }
        }
      }

      if (trezor_tx.operations[key].signer) {
        // Convert signer object to correct signer structore for Trezor
        if(trezor_tx.operations[key].signer.ed25519PublicKey) {
          let keypair = StellarSdk.Keypair.fromPublicKey(
            trezor_tx.operations[key].signer.ed25519PublicKey
          )
          trezor_tx.operations[key].signer.type = 0
          trezor_tx.operations[key].signer.key = Buffer.from(
            keypair.rawPublicKey()
          ).toString('hex')
          trezor_tx.operations[key].signer.weight = trezor_tx.operations[key].signer.weight
        } else if(trezor_tx.operations[key].signer.Sha256Hash) {
          trezor_tx.operations[key].signer.type = 1
          trezor_tx.operations[key].signer.key = Buffer.from(
            trezor_tx.operations[key].signer.Sha256Hash
          ).toString('hex')
          trezor_tx.operations[key].signer.weight = trezor_tx.operations[key].signer.weight
        } else if(trezor_tx.operations[key].signer.PreAuthTx) {
          trezor_tx.operations[key].signer.type = 2
          trezor_tx.operations[key].signer.key = Buffer.from(
            trezor_tx.operations[key].signer.PreAuthTx
          ).toString('hex')
          trezor_tx.operations[key].signer.weight = trezor_tx.operations[key].signer.weight
        } else {
          throw 'Invalid signer type'
        }
      }
    });

    console.log(trezor_tx)
  }

  if (transaction.memo) {
    switch (transaction.memo.type) {
      case 'text':
        trezor_tx.memo = { type: 1, text: transaction.memo.value };
        break;
      case 'id':
        trezor_tx.memo = { type: 2, id: transaction.memo.value };
        break;
      case 'hash':
        trezor_tx.memo = { type: 3, hash: transaction.memo.value };
        break;
      case 'returnHash':
        trezor_tx.memo = { type: 4, hash: transaction.memo.value };
        break;
      default:
    }
  }
  return trezor_tx;
}

export default App;
