import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

let openSnackbarFn = () => {};

class Toast extends React.Component {
  state = {
    open: false,
    text: '',
  };

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  openSnackbar = ({ text }) => {
    this.setState({
      open: true,
      text,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      open: false,
      text: '',
    });
  };

  render() {
    const text = (
      <span
        id="snackbar-message-id"
        dangerouslySetInnerHTML={{ __html: this.state.text }}
      />
    );

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        message={text}
        autoHideDuration={3000}
        onClose={this.handleSnackbarClose}
        open={this.state.open}
        // SnackbarContentProps={{
        //   'aria-describedby': 'snackbar-message-id',
        // }}
      />
    );
  }
}

export function showToast({ text }) {
  openSnackbarFn({ text });
}

export default Toast;
