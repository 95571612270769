import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
import colors from '../config/colors.json';

const MARGIN_TOP = 172;
const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100vh',
    backgroundColor: '#fafafa',
    padding: theme.spacing(1),
  },
  header: {
    height: MARGIN_TOP - 40,
    minHeight: MARGIN_TOP - 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    // paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end',
    height: 40,
    minHeight: 40,
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(440)]: {
      width: '100%',
      margin: 0,
    },
  },
  content: {
    width: 336,
    justifyContent: 'center',
    [theme.breakpoints.down(440)]: {
      width: '100%',
    },
  },
});

const Page = props => {
  const { classes, children, noHeader, title, footer, header } = props;

  return (
    <div className={classes.container}>
      <a href={'https://rehive.com/'} target="_blank" rel="noopener noreferrer">
        <img
          style={{
            maxHeight: 100,
            maxWidth: 200,
            padding: '8px 8px',
          }}
          alt="rehive"
          src={'/logo.png'}
        />
      </a>
      <div className={classes.title}>
        <Typography align={'center'} variant={'h5'}>
          {'Stellar Signing Service'}
        </Typography>
      </div>

      <Paper className={classes.paper}>
        <div className={classes.content}>{children}</div>
      </Paper>
      <div className={classes.footer}>{footer}</div>
    </div>
  );
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
