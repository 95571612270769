import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

const _Button = props => {
  const classes = useStyles(props);

  return (
    <Button
      color={'primary'}
      variant={'contained'}
      classes={{
        label: classes.label,
        root: classes.button,
      }}
      {...props}
    />
  );
};

const useStyles = makeStyles(theme => ({
  label: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
  },
  button: { borderRadius: 100, margin: theme.spacing(1) },
}));

export default _Button;
